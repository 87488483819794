
/**
 * Se definen los colores a usar en el sitio
 */
$white      : #ffffff;
$black      : #000000;
$red        : #FF4358;
$gray       : #4D4D4D;
$gray-light : #cccccc;
$gray-dark  : #1A1A1A;

/**
 * Tamano de fuentes
 */
$light : 300;
$normal: 400;
$bold  : 800;

/**
 * Animacion de la Galeria
 */
 @-webkit-keyframes rebote {
    0%   {bottom: 0px;}
    25%  {bottom: 10px;}
    75%  {bottom: -10px;}
    100% {bottom: 0px;}
}
