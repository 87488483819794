
/**
 * Asignacion general de fuentes
 */
html, body {
    width: 100%;
    height: 100%;
    font-family: "Gotham A", "Gotham B", "Helvetica", "Arial";
    font-size: 16px;
    font-weight: $normal;
}

/**
 * Clase para los colores en las fuentes
 */
.f-gray {
    color: $gray;
}
.f-white {
    color: $white;
}
.f-red {
    color: $red;
}
.f-black {
    color: $black;
}
.f-gray-light {
    color: $gray-light;
}
.f-normal {
    font-weight: $normal;
}
.f-light {
    font-weight: $light;
}
.f-bold {
    font-weight: $bold;
}

/**
 *Clases para los colores de fondo
 */
.bg-red{
    background-color: $red;
}
.bg-black{
    background-color: $black;
}

/**
 *Clases para los colores de los bordes
 */
.bd-black{
    border-color: $black;
}
.bd-gray{
    border-color: $gray;
}
.bd-white{
    border-color: $white;
}

/**
 * Utiles varios
 */
.hide{
    display: none;
}
.clear{
    clear: both;
}


/**
 * Imagen de bloqueo landscape
 */
 #block-mls {
    display: none;
    @include this-is-for(mobile, landscape) {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: $red;
        img {
            position: absolute;
            display: block;
            width: 95%;
            max-width: 568px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
    @include this-is-for(tablets) {
        display: none;
    }
}

/**
 * Lineas menu y X
 */
.linea {
    display: block;
    background-color: $white;
    width: 50%;
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    @include add-prefixes(transition, all ease 0.5s);
}
.l1 {
    top: 35%;
}
.l2 {
    top: 50%;
    opacity: 1;
}
.l3 {
    top: 65%;
}

/**
 * CSS para el Menu
 */
.btn-menu {
    max-width: 80px;
    max-height: 80px;
    background: $black;
    width: 15vw;
    height: 15vw;
    position: absolute;
    position: fixed;
    z-index: 90;
    text-align: center;
    cursor: pointer;
    @include add-prefixes(transition, all ease 0.2s);

    &:hover {
        background-color: #232323;
    }

    &.close-menu {
        .l1 {
            @include add-prefixes(transform, rotate(45deg));
            top: 50%;
        }

        .l2 {
            opacity: 0;
        }

        .l3 {
            @include add-prefixes(transform, rotate(-45deg));
            top:  50%;
        }
    }
}

.btn-projects {
    max-width: 80px;
    max-height: 80px;
    background: $black;
    width: 15vw;
    height: 15vw;
    top: 15vw;
    position: absolute;
    position: fixed;
    z-index: 90;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: #232323;
    }

    @include this-is-for(tablets) {    
        top: 80px;
    }

    a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;

        span {
            display: block;
            position: relative;
            width: 0; 
            height: 0; 
            border-top: 4.5vw solid transparent;
            border-right: 5.5vw solid white;
            border-bottom: 4.5vw solid transparent;
            top: 2.5vw;
            left: 4vw;

            @include this-is-for(tablets) {    
                border-top: 15px solid transparent;
                border-right: 20px solid white;
                border-bottom: 15px solid transparent;
                top: 20px;
                left: 25px;
            }
        }
    }
}

#menu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 60;
    visibility: hidden;
    @include add-prefixes(transition, all ease 0.2s);

    &.show {
        visibility: visible;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    svg path {
        fill: $red;
    }

    .content {
        @include center-in-parent(100%, 100%);

        ul {
            @include center-in-parent(90%, 80%);
            max-width: 500px;
            padding: 0;
            @include this-is-for(tablets) {
                @include center-in-parent(100%, 80%);
            }
        }

        li {
            height: 12%;
            margin-top: -1px;
            position: relative;
            border-top: 1px solid;
            border-bottom: 1px solid;
            border-color: transparent;
            box-sizing: border-box;
            overflow: hidden;
            @include add-prefixes(transition, all ease 0.3s);

            @include this-is-for(desktops) {
                &:hover {
                    border-top: 1px solid $white;
                    border-bottom: 1px solid $white;
                    .bg {
                        display: block;
                        top: -25%;
                    }
                    a.span {
                        opacity: 0;
                    }
                    a.link {
                        bottom: 0;
                    }
                }
            }

            .bg {
                text-align: center;
                font-size: 7em;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.05);
                width: 150%;
                height: 150%;
                position: relative;
                top: -180%;
                left: -25%;
                display: none;
                @include add-prefixes(transition, all ease 0.3s);

                @include this-is-for(desktops) {
                    display: block;
                }

                @include this-is-for(large-desktops) {
                    font-size: 9em;
                }
            }

            a.span {
                @include center-in-parent(100%, 1em);
                font-size: 1.8em;
                text-decoration: none;
                display: block;
                text-align: center;
                color: $white;
                font-size: 1.2em;
                letter-spacing: 1px;
                font-weight: 100;
                @include add-prefixes(transition, all ease 0.3s);

                @include this-is-for(tablets) {
                    opacity: 1;
                }

                @include this-is-for(desktops) {
                    opacity: 1;
                }
            }

            a.link {
                display: none;
                @include center-in-parent(100%, 1em);
                text-decoration: none;
                top: 0;
                bottom: -150%;
                text-align: center;
                position: absolute;
                color: $white;
                z-index: 10;
                font-size: 3em;
                @include add-prefixes(transition, all ease 0.3s);

                @include this-is-for(tablets) {
                    bottom:0;
                }

                @include this-is-for(desktops) {
                    display: block;
                    bottom: -160%;
                }
            }
        }

        .social-networks {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            border:1px solid white;
            margin: 15% auto;
            cursor: pointer;
            @include add-prefixes(transition, all ease 0.2s);

            @include this-is-for(tablets) {
                height: 80px;
                width: 80px;
            }

            @include this-is-for(desktops) {
                &:hover {
                    @include add-prefixes(transform, scale(1.1));
                }
            }
            img {
                display: block;
                margin: 15% auto;
                border: none;
                height: 70%;
            }
        }

        .logo {
            height: 25%;
            text-align: center;
            border-color: transparent;
            &:hover {
                border-color: transparent;
            }
            img {
                height: 70%;
                margin: 0 auto;
            }
        }
    }
}

#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $red;
    z-index: 1000;

    &.fadeout {
        @include add-prefixes(transition, all 0.4s ease-in);
        @include add-prefixes(transform, scale(3));
        opacity: 0;

        .circle {
            display: none;
        }
    }

    .logo {
        @include center-in-parent(250px, 120px);
    }

    .circle {
        @include center-in-parent(30vh, 30vh);
        box-sizing: border-box;
        border: 4px solid $white;
        border-radius: 50%;
        opacity: 0.5;
        @include add-prefixes(transform, scale(0));

        #include this-is-for(tablets) {
            @include center-in-parent(30vw, 30vw);
        }
    }

    .animCircle {
        @include add-prefixes(transition, all 5s ease);
        @include add-prefixes(transform, scale(5));
        opacity: 0.1
    }

}

