
@import
    "scss/variables",
    "scss/mixins",
    "scss/reset",
    "scss/basic";


body.home {
    @include this-is-for(tablets, landscape) {
        position: relative;
        overflow: hidden;
    }
}

#home {
    width: 100%;

    @include this-is-for(tablets, landscape) {
        @include center-in-parent(99vw, calc(100% - 1vw));
    }

    .project {
        box-sizing: border-box;
        width: 100vw;
        height: 62.5vw;
        padding: 3.125vw 5vw;
        margin: auto;
        overflow: hidden;

        @include this-is-for(tablets, landscape) {
            width: 33.3%;
            height: 33.3%;
            padding: 0.5% 0.5%;
            float: left;
            position: relative;
        }

        @include this-is-for(desktops) {
            @include add-prefixes(filter, grayscale(100%));
            /*filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");*/ /* Firefox 3.5+ */ /* Using SVG */ 
            filter: Gray(); /* IE4-8 and 9 */
            @include add-prefixes(transition, all 0.3s ease-out);
        }

        &:hover {
            @include add-prefixes(filter, none);

            .hover {
                opacity: 1;
                right: 0;

                .type {
                    left: 0;
                    opacity: 1;
                }
                .title {
                    left: 0;
                    opacity: 1;
                }
            }
        }

        a {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &.m-uvee {
            @include add-prefixes(filter, none);

            a {
                background: $red;
                cursor: default;

                img {
                    box-sizing: border-box;
                    @include center-in-parent(200px, auto);
                }
            }
        }

        img {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;

            @include this-is-for(tablets, landscape) {
                @include center-in-parent(100%, auto);

            }
        } 
    }

    .hover {
        display: none;
        width: 100%;
        height: 100%;
        background: #000;
        box-sizing: border-box;
        vertical-align: middle;

        @include this-is-for(desktops) {
            display: block;
            opacity: 0;
            padding: 5% 5% 12%;
            @include center-in-parent(70%, 25vh);
            max-width: 200px;
            max-height: 200px;
            right: 20%;
        }

        @include add-prefixes(transition, all 0.2s ease-out);

        .type {
            left: -50px;
            opacity: 0;
            position: relative;
            color: $gray;
            font-size: 0.8em;
            line-height: 1.5em;
            @include add-prefixes(transition, all 0.2s ease-out 0.2s);
        }

        .title {
            left: -50px;
            opacity: 0;
            position: relative;
            font-size: 1.2em;
            line-height: 1.2em;
            @include add-prefixes(transition, all 0.2s ease-out 0.3s);

            span {
                display: block;
            }
        }

        .rectangle {
            background: $red;
            @include center-in-parent(30%, 30%);
            top: auto;
            bottom: -15%;

            img {
                @include center-in-parent(50%, 50%);
            }
        }
    }
}
