
/**
 * Centra un elemento mediante posicion absoluta dentro
 * de un contenedor con posicion relativa.
 *
 * Siempre es necesario definir el alto y ancho del elemento.
 */
@mixin center-in-parent($width, $height) {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: $width;
    height: $height;
}


@mixin make-video-tabs($items, $height) {
    .content {
        position: relative;
        height: $height * $items;
        .vi-hover:before {
            border-top: ($height / 2) solid transparent;
            border-bottom: ($height / 2) solid transparent;
            border-left: ($height / 6) solid transparent;
            border-right: ($height / 6) solid $red;
            left: $height / -3.1;
        }
    }

    .item {
        position: static;
        height: $height;
    }
}


@mixin add-prefixes($property, $value) {
    -webkit-#{$property}: $value;
       -moz-#{$property}: $value;
        -ms-#{$property}: $value;
            #{$property}: $value;
}


@mixin this-is-for($media, $orientation: false) {
    @if $media == mobile and $orientation != false {
        @media only screen and (orientation: $orientation) { @content; }
    }

    @else if $media == tablets and $orientation != false {
        @media only screen and (min-width: 48em) and (orientation: $orientation) { @content; }
    }

    @else if $media == tablets {
        @media only screen and (min-width: 48em) { @content; }
    }

    @else if $media == desktops {
        @media only screen and (min-width: 64.063em) { @content; }
    }

    @else if $media == large-desktops {
        @media only screen and (min-width: 90.063em) { @content; }
    }
}

